import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/PersonOutline';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authUserState } from '../../../api/appState';

export type MainNavigationContentItemProfileProps = StandardDivProps<MainNavigationContentItemProfileClassKey>;

export type MainNavigationContentItemProfileClassKey = 'root';

const themeSettings = { name: 'MainNavigationContentItemProfile' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentItemProfileClassKey, {}>({
      root: { color: theme.palette.common.white, marginLeft: 'auto' },
    }),
  themeSettings,
);

const MainNavigationContentItemProfile = React.forwardRef<HTMLAnchorElement, MainNavigationContentItemProfileProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const authUser = useRecoilValue(authUserState) || { name: 'N/A' };

    return (
      <Tooltip title={authUser.name}>
        <IconButton ref={ref} component={RouterLink} className={clsx(classes.root, className)} to='/profil'>
          <PersonIcon />
        </IconButton>
      </Tooltip>
    );
  },
);

MainNavigationContentItemProfile.displayName = 'MainNavigationContentItemProfile';

export default withThemeProps(themeSettings)(MainNavigationContentItemProfile);
