import deepOrange from '@material-ui/core/colors/deepOrange';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Create a theme instance.
const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        light: '#515151',
        // main: '#3466c1',
        main: '#515151',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      secondary: deepOrange,
      appBar: {
        background: '#000000',
        color: {
          contrastText: '#FFFFFF',
          logo: '#a4db0b',
        },
      },
      mainNavigation: {
        background: '#515151',
        backgroundActive: '#FFFFFF',
      },
    },
    typography: {
      // fontSize: 12,
      h1: {
        fontSize: '2.8rem',
        fontWeight: 900,
        // margin: '16px 0 24px 0',
      },
      h2: {
        fontSize: '2.4rem',
        fontWeight: 400,
        // margin: '16px 0 24px 0',
      },
      h3: {
        fontSize: '2rem',
      },
      h4: {
        fontSize: '1.8rem',
      },
      h5: {
        fontSize: '1.6rem',
      },
      h6: {
        fontSize: '1.4rem',
      },
      subtitle1: {
        fontSize: '1.1rem',
      },
      subtitle2: {
        fontSize: '1rem',
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '@media(max-width:959px)': {
            whiteSpace: 'initial',
          },
        },
      },
      // SxComProductImage: {
      //   'size-big-landscape': {
      //     '@media(min-width:960px)': {
      //       height: 600,
      //       width: 600,
      //     },
      //   },
      // },
    },
  }),
);

export default theme;
