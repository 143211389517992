import invariant from 'tiny-invariant';

export function buildInvoiceHref(invoice: { id: string }): string {
  return `/profil/faktury/${invoice.id}`;
}

function transformInvoice<T extends { id: string }>(locale: string, invoices: T[]): (T & { href: string })[] {
  invariant(Array.isArray(invoices), 'transformInvoice requires invoices to be defined');

  return invoices.map((invoice: T) => ({
    ...invoice,
    href: buildInvoiceHref(invoice),
  }));
}

export default transformInvoice;
