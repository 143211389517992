import { QueryOrderSettings, QueryPaginationSettings, TableFilterField } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { CreditNote, CreditNoteRecord } from '../../@sprinx/knihovka-types/creditNotes';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import {
  buildListQuery,
  createListInitialSettings,
  ListCallParams,
} from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, localeState } from '../appState';
import transformCreditNote from './transformCreditNote';

export type CreditNoteListItem = CreditNote<CreditNoteRecord, 'contact'> & {
  href: string;
};

export const creditNotesQuery = selector<MoleculerListResult<CreditNoteListItem>>({
  key: 'creditNotes',
  get: ({ get }) => {
    const initialState = get(creditNotesInitialState);
    if (initialState) {
      return initialState;
    }

    const apiClient = get(apiClientState);
    const locale = get(localeState);
    const params = get(creditNotesCallParamsState);
    get(creditNotesReloadTrigger);

    return getCreditNotes(apiClient, locale, params);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(creditNotesReloadTrigger, (n) => n + 1);
    }
  },
});

export const creditNotesCallParamsState = GlobalStateRegister.register(
  atom<ListCallParams>({
    key: 'creditNotesCallParams',
    default: createListInitialSettings({
      rowsPerPage: 10,
      orderBy: 'number',
      orderDirection: 'desc',
    }),
  }),
);

export const creditNotesOrderByState = selector<QueryOrderSettings>({
  key: 'creditNotesOrderBy',
  get: ({ get }) => {
    const params = get(creditNotesCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(creditNotesCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const creditNotesPaginationState = selector<QueryPaginationSettings & { total: number }>({
  key: 'creditNotesPagination',
  get: ({ get }): QueryPaginationSettings & { total: number } => {
    const params = get(creditNotesCallParamsState);
    const queryResult = get(creditNotesQuery);

    return {
      page: queryResult.page || params.pagination.rowsPerPage,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(creditNotesCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          // ...prevValue.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const creditNotesFilterState = selector<TableFilterField<any>[]>({
  key: 'creditNotesFilter',
  get: ({ get }) => {
    const params = get(creditNotesCallParamsState);
    return (params.filter as TableFilterField<any>[]) || [];
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const nextFilter = newValue.filter(Boolean);

      set(creditNotesCallParamsState, (prev) => ({
        ...prev,
        filter: nextFilter,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const creditNotesInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<CreditNoteListItem> | undefined>({
    key: 'creditNotesInitialState',
    default: undefined, // createListDefaultResult<CreditNotesProduct>(),
  }),
);

export function getCreditNotes(
  apiClient: ApiClient,
  locale: string,
  params: ListCallParams,
): Promise<MoleculerListResult<CreditNoteListItem>> {
  return apiClient
    .get<MoleculerListResult<Omit<CreditNoteListItem, 'href'>>, Record<string, any>>(
      '/v1/credit-notes',
      buildListQuery(params),
    )
    .then((r) => ({
      ...r,
      rows: transformCreditNote(locale, r.rows),
    }));
}

export const creditNotesReloadTrigger = atom<number>({
  key: 'creditNotesReloadTrigger',
  default: 0,
});
