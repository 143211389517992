import { asyncComponent } from '@jaredpalmer/after';
import qs from 'qs';
import NotFoundErrorPage from './404';
import InternalErrorPage from './500';
import { replaceRouteParams } from './@sprinx/react-after-razzle';
import Placeholder from './AppComponentLoader';

const linksMap = {
  catalogue: {
    cs: '/katalog',
  },
};

// function getRoutesDefinitionsForCode(
//   code: keyof typeof linksMap,
//   routeCfg: (path: string) => AsyncRouteProps<any>,
// ): AsyncRouteProps<any>[] {
//   return Object.entries(linksMap[code]).map(([, /* locale */ path]) => routeCfg(path));
// }

export function getRouteForCode(code: keyof typeof linksMap, locale: 'cs', params?: Record<string, any>): string {
  const pathProto = linksMap[code][locale];
  return !params
    ? pathProto
    : (() => {
        const [path, queryParams] = replaceRouteParams(pathProto, params);
        return !queryParams
          ? path
          : `${path}${Object.keys(queryParams).length ? qs.stringify(queryParams, { addQueryPrefix: true }) : ''}`;
      })();
}

export default [
  {
    path: '/',
    exact: true,
    redirectTo: '/catalogue',
    // component: asyncComponent({
    //   loader: () => import('./pages/HomePage'),
    //   Placeholder,
    // }),
  },
  {
    path: '/catalogue/favorite',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/FavoriteProductsPage'),
      Placeholder,
    }),
  },
  {
    path: '/catalogue/:categories*',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
  },
  {
    path: '/special/:categories*',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
  },
  // Product details
  {
    path: '/:sn([^/]+)-product-:id([^/]+)',
    component: asyncComponent({
      loader: () => import('./pages/ProductDetailPage'),
      Placeholder,
    }),
  },
  {
    path: '/hromadny-nakup',
    component: asyncComponent({
      loader: () => import('./pages/BulkPurchasePage'),
      Placeholder,
    }),
  },
  {
    path: '/dokumenty-ke-stazeni',
    component: asyncComponent({
      loader: () => import('./pages/DownloadDocumentsPage'),
      Placeholder,
    }),
  },
  {
    path: '/cart/summary',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartSummaryPage'),
      Placeholder,
    }),
  },
  {
    path: '/dekujeme-za-objednavku',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderThankYouPage'),
      Placeholder,
    }),
  },

  {
    path: '/objednavka-odeslana-ke-schvaleni',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderSentToApprovalPage'),
      Placeholder,
    }),
  },

  /** PROFIL */
  {
    path: '/profil/schvaleni-objednavek',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartsApprovalPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/schvaleni-objednavek/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartApprovalPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfilePage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/objednavky',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileOrdersPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/objednavky/:id',
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileOrderDetailPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/faktury',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileInvoicesPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/faktury/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileInvoiceDetailPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/dobropisy',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileCreditNotesPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/dobropisy/:id',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileCreditNoteDetailPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/dokumenty',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileDocumentsPage'),
      Placeholder,
    }),
  },

  {
    path: '/profil/nakupni-seznamy',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileShoppingListsPage'),
      Placeholder,
    }),
  },

  /** DALSI STRANKY */

  {
    path: '/obchodni-podminky',
    component: asyncComponent({
      loader: () => import('./pages/TermsAndConditionsPage'),
      Placeholder,
    }),
  },

  {
    path: '/prihlaseni',
    component: asyncComponent({
      loader: () => import('./pages/LoginPage'),
      Placeholder,
    }),
  },
  {
    path: '/odhlaseni',
    component: asyncComponent({
      loader: () => import('./pages/LogoutPage'),
      Placeholder,
    }),
  },
  {
    path: '/zmena-hesla',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/PasswordRenewal'),
      Placeholder,
    }),
  },
  {
    path: '/objednavka-zrusena',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderDisapprovalSendingPage'),
      Placeholder,
    }),
  },
  {
    path: '/_error',
    component: InternalErrorPage,
  },
  {
    component: NotFoundErrorPage,
  },
];
