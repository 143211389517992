import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LoginPageLogo from '../../../pages/LoginPage/LoginPageLogo';
import MainNavigationContentItem from './MainNavigationContentItem';
import MainNavigationContentItemTree from './MainNavigationContentItemTree';
import { useTranslate } from '@sprinx/react-globalize';
import { Favorite } from '@material-ui/icons';

export type MainNavigationContentProps = StandardDivProps<MainNavigationContentClassKey>;

export type MainNavigationContentClassKey =
  | 'root'
  | 'primary'
  | 'secondary'
  | 'termsOfServices'
  | 'logo'
  | 'secondaryDiv';

const themeSettings = { name: 'MainNavigationContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      primary: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: theme.spacing(1),
      },
      secondary: {
        minHeight: theme.spacing(18),
      },
      secondaryDiv: {
        paddingTop: theme.spacing(0.5),
        textAlign: 'center',
      },
      termsOfServices: {
        color: 'white',
      },
      logo: {
        backgroundColor: 'white',
        margin: theme.spacing(2),
        padding: theme.spacing(1, 1, 0.25, 1),
        borderRadius: theme.spacing(1),
      },
    }),
  themeSettings,
);

const MainNavigationContent = React.forwardRef<HTMLDivElement, MainNavigationContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const t = useTranslate();
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.primary}>
          {/* <MainNavigationContentItem icon={<HomeIcon />} primary={'Domov'} to='/' /> */}
          {/* <MainNavigationContentItem icon={<PriorityHighIcon />} primary={'Kampaně'} to='/special/campaign' /> */}
          <MainNavigationContentItemTree primary={'Všechny produkty'} to='/catalogue' />

          {/* <MainNavigationContentItem icon={<AttachMoneyIcon />} primary={'Výprodej'} to='/special/sale' /> */}

          {/* <MainNavigationContentItem icon={<AnnouncementIcon />} primary={'Novinky'} to='/special/introduction' /> */}
          <MainNavigationContentItem
            icon={<AddShoppingCartIcon />}
            primary='Hromadné vložení do košíku'
            to='/hromadny-nakup'
          />
          <MainNavigationContentItem
            icon={<ArrowDownwardIcon />}
            primary='Dokumenty ke stažení'
            to='/dokumenty-ke-stazeni'
          />
          <MainNavigationContentItem
            icon={<Favorite />}
            primary={t('favoriteProductsPage/title')}
            to='/catalogue/favorite'
          />
        </div>
        <div className={classes.secondary}>
          {/* <Divider /> */}
          <div className={classes.secondaryDiv}>
            <div className={classes.logo}>
              <LoginPageLogo />
            </div>
            <Link component={RouterLink} to='/obchodni-podminky' color='textPrimary'>
              <span className={classes.termsOfServices}>Všeobecné obchodní podmínky</span>
            </Link>
          </div>
        </div>
      </div>
    );
  },
);

MainNavigationContent.displayName = 'MainNavigationContent';

export default withThemeProps(themeSettings)(MainNavigationContent);
