import { TranslationDictionary } from '@sprinx/react-globalize/types';
import kebabCase from 'lodash/kebabCase';
import invariant from 'tiny-invariant';

export function productBuildHref<
  T extends { extra?: { mainProductId?: string }; id: string; name: TranslationDictionary }
>(locale: string, product: T): string {
  const id = product?.extra?.mainProductId || product.id;
  return `/${kebabCase(
    (typeof product.name === 'string' ? product.name : product.name.find((i) => i.language === locale)?.text) || '',
  )}-product-${id}`;
}

function transformProduct<T extends { id: string; name: TranslationDictionary }>(
  locale: string,
  products: T[],
): (T & { href: string })[] {
  invariant(Array.isArray(products), 'transformProduct requires products to be defined');

  return products.map((product: T) => ({
    ...product,
    href: productBuildHref(locale, product),
  }));
}

export default transformProduct;
