import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authUserState } from '../../api/appState';
import {
  shoppingCartInitializationDecisionState,
  shoppingCartState,
  useShoppingCartPrepareForSessionCall,
} from '../../api/shoppingCart';

const ShoppingBootstrap: React.FC = () => {
  const user = useRecoilValue(authUserState);

  const setShoppingCart = useSetRecoilState(shoppingCartState);
  const setDecistion = useSetRecoilState(shoppingCartInitializationDecisionState);

  const prepareCart = useShoppingCartPrepareForSessionCall();

  React.useEffect(() => {
    if (user) {
      prepareCart().then((res) => {
        // TODO: handle result error

        if (res.status) {
          setDecistion(res);
        } else if (res.cart) {
          setShoppingCart(res.cart);
        }
      });
    }
  }, [prepareCart, setDecistion, setShoppingCart, user]);

  return null;
};

ShoppingBootstrap.displayName = 'ShoppingBootstrap';

export default ShoppingBootstrap;
