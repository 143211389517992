import React from 'react';
import { Route } from 'react-router-dom';
import { AfterPageProps } from './@sprinx/react-after-razzle';
import ErrorInternal from './components/ErrorInternal';

export type InternalErrorPageProps = AfterPageProps;

const InternalErrorPage: React.FC<InternalErrorPageProps> = () => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 500;
        return <ErrorInternal />;
      }}
    />
  );
};

InternalErrorPage.displayName = 'InternalErrorPage';

export default InternalErrorPage;
