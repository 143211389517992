import { NoSsr } from '@material-ui/core';
import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { customerNotificationOpenState } from '../../../api/customerNotification';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import MainLayoutCustomerNotificationContent from './MainLayoutCustomerNotificationContent';

const MainLayoutCustomerNotification: FC = () => {
  const open = useRecoilValue(customerNotificationOpenState);

  return open ? (
    <NoSsr>
      <ErrorBoundary>
        <MainLayoutCustomerNotificationContent />
      </ErrorBoundary>
    </NoSsr>
  ) : null;
};

MainLayoutCustomerNotification.displayName = 'MainLayoutCustomerNotification';

export default MainLayoutCustomerNotification;
