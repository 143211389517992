import React from 'react';
import { Route } from 'react-router-dom';

export default function NotFoundErrorPage(): JSX.Element {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return <div>The Page You Were Looking For Was Not Found</div>;
      }}
    />
  );
}
