import { atomFamily, selectorFamily } from 'recoil';
import { EntityId, Invoice, InvoiceRecord } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type InvoiceDetailInvoice = Invoice<InvoiceRecord, 'contact' | 'owner'>;

export const getInvoiceDetail = (
  apiClient: ApiClient,
  params: { id: EntityId; populate?: string[] },
): Promise<InvoiceDetailInvoice> => {
  return apiClient.get<InvoiceDetailInvoice, { id: EntityId }>('/v1/invoices/:id', params);
};

export const invoiceDetailInitialStateFamily = GlobalStateRegister.register(
  atomFamily<InvoiceDetailInvoice | undefined, EntityId>({
    key: 'invoiceDetailInitial',
    default: undefined,
  }),
  'invoiceDetailInitial',
);

export const invoiceDetailQuery = selectorFamily<InvoiceDetailInvoice, EntityId>({
  key: 'invoiceDetail',
  get: (invoiceId) => ({ get }) => {
    const initial = get(invoiceDetailInitialStateFamily(invoiceId));
    if (initial) return initial;

    const apiClient = get(apiClientState);

    return getInvoiceDetail(apiClient, { id: invoiceId, populate: ['contact'] });
  },
});
