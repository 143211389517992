import { atom, selector } from 'recoil';
import { ProductParameterGroupTypes } from '../../@sprinx/knihovka-types/products';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type ProductParameterGroupTypesHandler = () => Promise<ProductParameterGroupTypes>;

export function getProductParameterGroupTypes(apiClient: ApiClient): ReturnType<ProductParameterGroupTypesHandler> {
  return apiClient.get<ProductParameterGroupTypes, {}>('/v1/product-parameter-group-types', {});
}

export const productParameterGroupTypesInitialState = GlobalStateRegister.register(
  atom<ProductParameterGroupTypes | undefined>({
    key: 'productParameterGroupTypesInitial',
    default: undefined,
  }),
);

export const productParameterGroupTypesQuery = selector<ProductParameterGroupTypes>({
  key: 'productParameterGroupTypes',
  get: ({ get }) => {
    const initial = get(productParameterGroupTypesInitialState);
    if (initial) return initial;

    const apiClient = get(apiClientState);
    return getProductParameterGroupTypes(apiClient);
  },
});
