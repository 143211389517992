import invariant from 'tiny-invariant';

function transformCreditNote<T extends { id: string }>(locale: string, creditNotes: T[]): (T & { href: string })[] {
  invariant(Array.isArray(creditNotes), 'transformCreditNote requires creditNotes to be defined');

  const buildHref = (creditNote: T): string => {
    return `/profil/dobropisy/${creditNote.id}`;
  };

  return creditNotes.map((creditNote: T) => ({
    ...creditNote,
    href: buildHref(creditNote),
  }));
}

export default transformCreditNote;
