import { createStyles, makeStyles } from '@material-ui/core';
import DialogSimple from '@sprinx/react-mui-components/DialogSimple';
import React, { FC, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { customerNotification, customerNotificationOpenState } from '../../../api/customerNotification';

const themeSettings = { name: 'MainLayoutCustomerNotificationContent' };
const useStyles = makeStyles(
  () =>
    createStyles({
      root: {},
      content: {
        '& figure.image img': {
          width: '100%',
        },
        '& figure.image': {
          width: '100%',
          margin: 0,
          padding: 0,
          border: 0,
        },
      },
    }),
  themeSettings,
);

const MainLayoutCustomerNotificationContent: FC = () => {
  const classes = useStyles({ classes: {} });
  const setOpen = useSetRecoilState(customerNotificationOpenState);
  const notification = useRecoilValue(customerNotification);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return notification && notification.show ? (
    <DialogSimple open title={notification.title} onClose={handleClose} fullWidth maxWidth='lg'>
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: notification.body }} />
    </DialogSimple>
  ) : null;
};

MainLayoutCustomerNotificationContent.displayName = 'MainLayoutCustomerNotificationContent';

export default MainLayoutCustomerNotificationContent;
